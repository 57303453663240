import { Component, Inject, OnInit } from '@angular/core';

import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get } from 'lodash';
import { MetaService } from 'lib/services/meta.service';
import { environment } from '../../../../environments/environment'; // Import the environment file

const scriptName = 'cms-contact-us.component';

@Component({
    selector: 'app-cms-contact-us',
    templateUrl: './cms-contact-us.component.html',
    styleUrls: ['./cms-contact-us.component.scss'],
})
export class CmsContactUsComponent implements OnInit {
    entry: any = {};
    formId: any;
    interest: any;

    constructor(
        private cmsService: CmsService,
        private metaService: MetaService
    ) {}

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('contact_us');
            const id = get(this.entry, 'marketo_forms.id');
            if (!id) {
                throw new Error('Marketo form ID is missing on CMS entry: contentTypeId: contact_us');
            }
            this.formId = `mktoForm_${id}`;
            this.interest = `${environment.urls.base}/Contact-Us`;
        } catch (err) {
            console.error(...new ExxComError(539941, scriptName, err).stamp());
        }
    }
}
