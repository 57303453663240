<ng-container *ngIf="!isEmpty(get(featureSolutionsRow, 'feature_solutions', ''))">
    <div *ngFor="let row of get(featureSolutionsRow, 'feature_solutions.feature_solutions', []); let i = index">
        <section id="rows" class="d-flex flex-column container-gridless feature-solutions">
            <div class="d-flex flex-row justify-content-center">
                <h2 class="text-center col-md-7 solution-title">
                    {{ get(featureSolutionsRow.feature_solutions, 'feature_solutions_title', '') }}
                </h2>
            </div>
            <div id="feature-cards-container">
                <div *ngFor="let solution of get(row, 'feature_solutions', [])" class="feature-card">
                    <div class="d-flex flex-column align-items-center w-100">
                        <div id="cms-partial-category-feature-solutions-img" class="text-center">
                            <a [routerLink]="get(solution, 'spec_link.routerLink', '')"
                                ><img *ngIf="get(solution, 'image.href', '')" [src]="getWebpImg(solution.image.href)" [alt]="solution.image.title"
                            /></a>
                        </div>
                        <div class="mpn-title-spacing">
                            <h5 class="text-center mpn">{{ solution?.part_number }}</h5>
                            <a [routerLink]="get(solution, 'spec_link.routerLink', '')" class="solution-link"
                                ><h4 class="text-center mt-0 mb-0 title">
                                    <ng-container *ngIf="get(solution, 'title', ''); let splitTitle">
                                        <span>{{ splitWord(splitTitle).firstPart }}</span>
                                        <span class="last-word">
                                            <span [class.last-word-text]="get(solution, 'title', '')"
                                                >{{ ' ' }}{{ splitWord(splitTitle).lastPart }}</span
                                            >
                                            <i-feather name="chevron-right"></i-feather>
                                        </span>
                                    </ng-container></h4
                            ></a>
                        </div>
                        <div class="gap-container">
                            <div *ngIf="solution.exxcomIsActive && solution?.product?.price" class="starting">
                                <p class="starting-copy">Starting at</p>
                                <span class="starting-price">{{ solution?.product?.price }}</span>
                            </div>

                            <ng-container *ngIf="solution?.custom_notes?.custom_note[0]?.length > 1; else elseBlock">
                                <div class="custom-note-container">
                                    <ng-container *ngFor="let note of get(solution, 'custom_notes.custom_note', [])">
                                        <p class="custom-note">{{ note }}</p>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-template #elseBlock>
                                <hr *ngIf="solution?.solution_specs || solution?.product?.price" class="m-0 p-0" />
                            </ng-template>

                            <div *ngIf="solution?.solution_specs" class="text-center spec" [innerHTML]="solution?.solution_specs"></div>

                            <div *ngIf="!solution?.solution_specs" class="text-center spec" [innerHTML]="solution?.product?.highlights"></div>

                            <div class="d-flex flex-column justify-content-center button-container w-100">
                                <a
                                    *ngIf="get(solution, 'button_text_select', '') && !solution.isBlackListed && solution.exxcomIsActive"
                                    class="button-primary button-base"
                                    [href]="['https://configurator.sabrepc.com' + '/configure/' + get(solution, 'part_number', '')]"
                                >
                                    <span class="d-flex justify-content-center align-items-center">
                                        <i-feather name="tool" class="tool-icon mr-1"></i-feather>
                                        Configure</span
                                    >
                                </a>
                                <button
                                    *ngIf="get(solution, 'inquiry_form.id', '') && get(solution, 'no_form', '') !== true"
                                    class="button-secondary button-base"
                                    (click)="
                                        bootstrapMarketoService.openBootstrapMarketoDialog(get(solution, 'inquiry_form.id', ''), 'edit', {
                                            title: get(solution, 'title', ''),
                                            url: solutionUrl,
                                            productInfo: {
                                                mpn: get(
                                                    featureSolutionsRow,
                                                    'feature_solutions.feature_solutions[0].feature_solutions[' + i + '].part_number',
                                                    ''
                                                ),
                                                solutionTitle: get(
                                                    featureSolutionsRow,
                                                    'feature_solutions.feature_solutions[0].feature_solutions[' + i + '].solution_title',
                                                    ''
                                                )
                                            }
                                        })
                                    "
                                >
                                    <span class="d-flex justify-content-center align-items-center">
                                        <i-feather name="message-circle" class="button-icon mr-1"></i-feather>
                                        {{ get(solution, 'button_text_select', '') }}</span
                                    >
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</ng-container>
<hr *ngIf="get(featureSolutionsRow, 'feature_solutions.split_line', '')" class="m-0" />
