<div id="cms-contact-us">
    <section class="fx-row-container fx-row-h-left mt-4 title">
        <div class="col-md-5">
            <h1>{{ entry?.contact_us_title }}</h1>
        </div>
    </section>

    <section class="fx-wrap fx-row-container fx-row fx-row-h-space-between contact-body mb-4 px-3">
        <div class="col-md-6 card-1">
            <lib-marketo-form [formId]="formId" [interest]="interest" class="contact-us-marketo-form"></lib-marketo-form>
        </div>

        <div class="col-md-5 contact-section">
            <div [innerHTML]="entry?.contact_info | safe: 'html'" class="pb-0 pl-3"></div>
        </div>
    </section>
</div>
